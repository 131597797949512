import api from '../../../utils/Api';

// Action type
export const GET_ORG_PROFILE = 'organizationProfile/LOAD';
export const CREATE_ORG_PROFILE = 'organizationProfile/CREATE';
export const EDIT_ORG_PROFILE = 'organizationProfile/EDIT';
export const CLEAR_ORG_PROFILE = 'organizationProfile/CLEAR';
export const GET_ORG_REPRESENTATIVES = 'organizationProfile/GET_ORG_REPRESENTATIVES';
export const DELETE_ORG_REPRESENTATIVE = 'organizationProfile/DELETE_ORG_REPESENTATIVE';

// Action creator
export const getOrgProfile = organizationProfile => {
  return {
    type: GET_ORG_PROFILE,
    organizationProfile,
  };
};

export const createOrgProfile = organizationProfile => {
  return {
    type: CREATE_ORG_PROFILE,
    organizationProfile,
  };
};

export const editOrgProfile = organizationProfile => {
  return {
    type: EDIT_ORG_PROFILE,
    organizationProfile,
  };
};

export const clearOrgProfile = () => {
  return {
    type: CLEAR_ORG_PROFILE,
  };
};

export const getOrgRepresentatives = representatives => {
  return {
    type: GET_ORG_REPRESENTATIVES,
    representatives,
  };
};

export const deleteOrgRepresentative = id => {
  return {
    type: DELETE_ORG_REPRESENTATIVE,
    id,
  };
};

//  Get organization profile thunk
export const getOrgProfileThunk = () => async dispatch => {
  try {
    const response = await api.get('/organization/profile/');
    if (response.ok) {
      dispatch(getOrgProfile(response.data || {}));
      return response;
    } else {
      return response.data.error;
    }
  } catch (error) {
    return error;
  }
};

export const createOrgProfileThunk = payload => async dispatch => {
  try {
    const response = await api.post('/organization/profile/', payload);
    if (response.ok) {
      dispatch(createOrgProfile(response.data));
      return response;
    }
    throw new Error('There was an issue creating an organization profile. Please try again later.');
  } catch (e) {
    throw e;
  }
};

export const editOrgProfileThunk = payload => async dispatch => {
  try {
    const response = await api.put('/organization/profile/', payload);
    if (response.ok) {
      dispatch(editOrgProfile(response.data));
      return response;
    } else {
      return response.data.error;
    }
  } catch (e) {
    throw e;
  }
};

export const getOrgRepresentativesThunk = () => async dispatch => {
  try {
    const response = await api.get('/organization/representatives/');
    if (response.ok) {
      dispatch(getOrgRepresentatives(response.data || []));
      return response;
    } else {
      const errorMessage =
        response.data && response.data.error
          ? response.data.error
          : 'Unexpected error occurred while fetching all organization representatives. Please try again.';
      throw new Error(errorMessage);
    }
  } catch (error) {
    throw error;
  }
};

export const createAndEditOrgRepresentativesThunk = data => async dispatch => {
  try {
    const response = await api.post('/organization/representatives/', data);
    if (response.ok) {
      dispatch(getOrgRepresentatives(response.data));
      return response;
    } else {
      if (response.data && response.data.error) {
        const error = response.data.error || 'Unexpected error occurred, please try later';
        throw new Error(error);
      }
    }
  } catch (e) {
    throw e;
  }
};

export const deleteOrgRepresentativeThunk = id => async dispatch => {
  try {
    const response = await api.delete(`organization/representatives/delete/${id}`);
    dispatch(deleteOrgRepresentative(id));
    return response;
  } catch (error) {
    throw error;
  }
};

export const checkSubdomainAvailabilityThunk = (subdomain, organizationId) => async dispatch => {
  try {
    const response = await api.getNonAuth('/organization/subdomain/', { subdomain, organizationId });
    if (response.ok) {
      return { available: false };
    }
  } catch (error) {
    if (error.status === 404) {
      return { available: true };
    } else if (error.status === 400) {
      return { available: false };
    } else {
      const errMessage = error?.data?.error || 'Unexpected error occurred, please try it later';
      throw new Error(errMessage);
    }
  }
};
